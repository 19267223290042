<template>
    <b-list-group>
        <h6 class="title">{{ title }}</h6>
        <b-list-group-item
            v-for="(item, index) in topItems"
            :key="index"
            class="d-flex justify-content-between align-items-center list-item"
        >
            <div>{{ item[labelField] }}</div>
            <div>{{ valueFormatter(item[valueField]) }}</div>
        </b-list-group-item>
        <b-list-group-item
            v-if="!topItems || !topItems.length"
            :key="`${title}-empty-table`"
            class="d-flex justify-content-center align-items-center list-item"
        >
            <div class="text-muted">No data available</div>
        </b-list-group-item>
        <div v-if="footerNote" class="text-muted footerNote">
            {{ footerNote }}
        </div>
    </b-list-group>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            },
            topCount: {
                type: Number,
                default: 3,
            },
            labelField: {
                type: String,
                default: 'label'
            },
            valueField: {
                type: String,
                default: 'value'
            },
            valueFormatter: {
                type: Function,
                default: value => value,
            },
            footerNote: {
                type: String,
                requred: false,
            }
        },
        computed: {
            topItems() {
                const sortedItems = [...this.items]
                return sortedItems.sort(
                    (a, b) => b[this.valueField] - a[this.valueField]
                ).slice(0, this.topCount)
            }
        }
    }
</script>

<style scoped>
    .list-item {
        border: none;
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 1rem;
    }
    .title {
        border: none;
        border-bottom: 2px solid #3b3d3e2d;
        margin-bottom: 0;
    }
    .footerNote {
        font-size: small;
    }
</style>
