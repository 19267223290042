<template>
    <div class="row carousel-container">
        <div v-if="showArrows" @click="prevArrowClick" id="btn-slider-prev"
            :class="['btn-slider', { 'btn-slider-disabled': !enableLeftArrow }]">
            <span aria-hidden="true" class="carousel-control-prev-icon"></span>
            <span class="sr-only">Previous videos</span>
        </div>
        <div :class="['text-center', { 'col-sm-6': videosPerPage >= 2, 'col-sm-12': videosPerPage < 2 }]"
            v-for="(video, index) in visibleVideos" :key="video.id">
            <div :class="[
                'shadow',
                'video-thumbnail',
                {
                    'video-thumbnail-first': index == 0 && showArrows,
                    'video-thumbnail-last': index == videosPerPage - 1 && showArrows
                }
            ]" @click="selectVideo(video)">
                <img :src="video.thumbnail" :alt="video.title" class="img-fluid" />
                <i class="fa fa-play"></i>
            </div>
            <p class="text-center">
                <a v-if="video.bottom_link" :href="video.bottom_link" target="_blank" class="text-center reset-link">
                    {{ video.bottom_text }}
                </a>
                <span v-else class="text-center">{{ video.bottom_text }}</span>
            </p>
        </div>
        <div v-if="showArrows" @click="nextArrowClick" id="btn-slider-next"
            :class="['btn-slider', { 'btn-slider-disabled': !enableRightArrow }]">
            <span aria-hidden="true" class="carousel-control-next-icon"></span>
            <span class="sr-only">Next videos</span>
        </div>
    </div>

    <modal-video v-if="selectedVideo" :key="selectedVideo.id" :modalId="'video_' + selectedVideo.id"
        :title="selectedVideo.title" :videoSrc="selectedVideo.video"></modal-video>
</template>

<script>
import ModalVideo from '../common/ModalVideo.vue';

export default {
    name: 'VideoSlider',
    components: {
        ModalVideo
    },
    props: {
        videos: {
            type: Array,
            required: true
        },
        videosPerPage: {
            type: Number,
            required: false,
            default: 2
        }
    },
    data() {
        return {
            currentIndex: 0,
            showArrows: false,
            enableLeftArrow: false,
            enableRightArrow: true,
            selectedVideo: null
        };
    },
    computed: {
        visibleVideos() {
            return this.videos.slice(this.currentIndex, this.currentIndex + this.videosPerPage);
        }
    },
    methods: {
        prevArrowClick() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.enableRightArrow = true;
                if (this.currentIndex == 0) {
                    this.enableLeftArrow = false;
                }
            }
        },
        nextArrowClick() {
            if (this.videos.length > this.currentIndex + this.videosPerPage) {
                this.currentIndex++;
                this.enableLeftArrow = true;
                if (this.videos.length == this.currentIndex + this.videosPerPage) {
                    this.enableRightArrow = false;
                }
            }
        },
        selectVideo(video) {
            this.selectedVideo = video;
            this.$nextTick(() => {
                this.$bvModal.show('video_' + video.id);
            });
        },
    },
    watch: {
        videos(newVideos) {
            this.showArrows = newVideos.length > this.videosPerPage;
        }
    }
}
</script>

<style scoped>
.carousel-container {
    position: relative;
}

.btn-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 167, 3, 0.6);
    cursor: pointer;
    margin-bottom: 50px;
    width: 30px;
}

.btn-slider:hover {
    background-color: rgba(25, 167, 3, 0.8);
}

.btn-slider-disabled,
.btn-slider-disabled:hover {
    background-color: rgba(25, 167, 3, 0.1);
    cursor: default;
}

#btn-slider-prev {
    left: 0;
}

#btn-slider-next {
    right: 0;
}

.video-thumbnail {
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
}

.video-thumbnail-first {
    margin-left: 20px;
}

.video-thumbnail-last {
    margin-right: 20px;
}

.video-thumbnail .fa-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
