<template>
    <div class="text-center mt-5">
        <p class="mb-4">
            <i
                :class="icon"
                aria-hidden="true"
                class="fa fa-3x text-info">
            </i>
        </p>

        <h2 class="h6 mb-3">{{ heading }}</h2>
        <p>{{ $t('post.empty.question') }}</p>

        <div
            v-if="metrics"
            v-html="$t('post.empty.metricsText')"
            class="text-left d-inline-block">
        </div>
        <div
            v-else
            v-html="$t('post.empty.text')"
            class="text-left d-inline-block">
        </div>

        <p v-if="contact" class="small text-muted mt-5">
            {{ $t("common.experiencingIssues") }}
            <a class="font-weight-bold text-info" href="mailto:info@branchup.com">
                <u>{{ $t("common.contactUs") }}</u>
            </a>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'EmptyPost',
        props: {
            contact: {
                type: Boolean,
                default: true,
                required: false
            },
            icon: {
                type: String,
                default: 'fa-calendar',
                required: false
            },
            metrics: {
                type: Boolean,
                required: false
            },
            title: {
                type: String,
                required: false
            }
        },
        data: function () {
            return {
                heading: this.$props.title ? this.$props.title : this.$t('post.empty.title')
            };
        }
    }
</script>
