export const pastPeriods = [
    {
        id: '1-week',
        days: 7,
        label: 'lastWeek'
    },
    {
        id: '2-week',
        days: 14,
        label: 'last2Weeks'
    },
    {
        id: '4-week',
        days: 28,
        label: 'last4Weeks'
    },
    {
        id: 'allDates',
        days: 0,
        label: 'allDates'
    }
];

export const futurePeriods = [
    {
        id: '1-week',
        days: 7,
        label: 'nextWeek'
    },
    {
        id: '2-week',
        days: 14,
        label: 'next2Weeks'
    },
    {
        id: '4-week',
        days: 28,
        label: 'next4Weeks'
    },
    {
        id: 'allDates',
        days: 0,
        label: 'allDates'
    }
];
