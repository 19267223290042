// Legacy source code that comes from old src/js/main.js file.
// Analyze to refactor when possible.

import $ from 'jquery';
import moment from 'moment-timezone';
import Toast from './toast.js';

const toast = new Toast();

$(document).ready(function () {

    $('.sidebar > ul > li a.has-sub-menu').on('click', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active');
        $(this).siblings('ul').slideToggle();
    });

    $('.header-navigation__toggle').on('click', function () {
        if ($(this).hasClass('open')) {
            $('.header-navigation__toggle').addClass('open');
            $('body').addClass('sidebar-open');
        } else {
            $('.header-navigation__toggle').removeClass('open');
            $('body').removeClass('sidebar-open');
        }
    });

    $('.custom-file-input').bind('change', function () {
        var filename = $(this).val();
        var curent = $(this);
        if (/^\s*$/.test(filename)) {
            curent.parents(".file-upload").removeClass('active');
            curent.siblings(".noFile").text("No file chosen...");
        } else {
            curent.parents(".file-upload").addClass('active');
            curent.parent().find(".noFile").text(filename.replace("C:\\fakepath\\", ""));
        }
    });

    $('.faq-list li a').on('click', function (e) {
        e.preventDefault();
        $(this).find('i').toggleClass('fa-plus fa-minus');
        $(this).parents('li').toggleClass('active-faq');
    });

    $('.header-navigation__toggle').on('click', function () {
        $(this).toggleClass('open');
        $('body').toggleClass('sidebar-open');
    });

    $('time').each(function () {
        var dt = $(this).data('datetime');
        var mdt = moment.tz(dt, "America/Toronto").local();
        $(this).html(mdt.format("LLLL"));
    });
});

export function process_api_form_response(response, success_action, url = undefined, error_msg = undefined, error_title = undefined, success_msg = undefined, success_title = undefined) {

    $('.invalid-feedback').remove();
    // process form response
    response.then(() => {
        // redirect to url
        if (success_action === 'redirect_to_page') {
            if (url === undefined) {
                return console.error('Trying to redirect with undefined url.');
            } else {
                return window.location = url;
            }

            // show success toaster
        } else if (success_action === 'print_toaster') {
            if (success_msg === undefined || success_title === undefined) {
                return console.error('Missing success msg and success title.');
            } else {
                return toast.success(success_msg, success_title);
            }

            // other functions are not expected
        } else {
            return console.error('Success function "' + success_action + '" is unexpected function');
        }

    }, error => {
        toast.error(error_msg, error_title);
        render_form_errors(error.response);
    });
}


export function render_form_errors(error, form = 'form') {
    $(form + ' .alert-danger').remove();
    $(form + ' .invalid-feedback').remove();

    if (error.status == 400) {

        // Set all fields to success on default
        $(form + ' input').each(function () {
            $(this).addClass('is-valid');
        });

        $(form + ' .v-select').each(function () {
            $(this).addClass('is-valid');
        });

        // Update individual fields to display error message
        $.each(error.data, function (field, error) {
            let dom_field = $('#' + field);
            dom_field.addClass('is-invalid');
            dom_field.parent().addClass('is-invalid');
            for (let e of error) {
                if (field === 'non_field_errors') {
                    $('.form-group').first().before('<div class="alert alert-danger" role="alert">' + e + '</div>');
                } else {
                    dom_field.after('<div class="invalid-feedback">' + e + '</div>');
                }

            }
        });
    }
}
