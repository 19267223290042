<template>
    <b-overlay :show="showOverlay" spinner spinner-variant="primary" style="min-height: 50px;">
        <slot :data="fetchedData"/>

        <div class="text-center h-5 text-muted" v-if="noDataMessage && thereIsNoData">
            {{ noDataMessage }}
        </div>
    </b-overlay>
</template>

<script>
    import axios from '@/setup/axios-setup.js';
    import Toast from '@/toast';
    const toast = new Toast();

    export default {
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            showLoading: {
                type: Boolean,
                required: false,
                default: true,
            },
            processResponse: {
                type: Function,
                required: false
            },
            okCallback: {
                type: Function,
                required: false
            },
            errorCallback: {
                type: Function,
                required: false
            },
            errorMessage: {
                type: String,
                required: false,
            },
            noDataMessage: {
                type: String,
                required: false,
            },
        },
        emits: ['update:fetchedData', 'update:loading', 'update:thereIsNoData'],
        data() {
            return {
                loading: false,
                fetchedData: null,
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.loading = true;
                axios.get(this.endpoint).then(response => {
                    let newFetchedData = null;
                    if (this.processResponse) {
                        newFetchedData = this.processResponse(response);
                    } else {
                        newFetchedData = response.data
                    }
                    this.fetchedData = newFetchedData;

                    if (this.okCallback) {
                        this.okCallback(response)
                    }
                }).catch(error => {
                    if (this.errorCallback) {
                        this.errorCallback(error);
                    } else {
                        if(this.errorMessage) {
                            toast.error(this.errorMessage, "Error")
                        } else {
                            this.handleError(error);
                        }
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            handleError(error) {
                if(error.response && error.response.status) {
                    switch (error.response.status) {
                        case 429:   // Too Many Requests
                            toast.error(this.$t('common.error429Description'), this.$t('common.error429'));
                            break;
                        default:
                            toast.error(this.defaultErrorMessage, "Error")
                            break;
                    }
                } else {
                    toast.error(this.defaultErrorMessage, "Error")
                }
            }
        },
        computed: {
            defaultErrorMessage() {
                return this.$t('common.endpointDefaultError');
            },
            showOverlay() {
                return (this.loading || (!this.loading && !this.fetchedData)) && this.showLoading;
            },
            thereIsNoData() {
                if (this.loading) {
                    return false;
                }

                if (!this.fetchedData) {
                    return true;
                }

                if (Array.isArray(this.fetchedData)) {
                    return !this.fetchedData.length;
                }

                if (typeof this.fetchedData === 'object') {
                    return !Object.keys(this.fetchedData).length;
                }

                return false;
            }
        },
        watch: {
            loading(newLoading) {
                this.$emit('update:loading', newLoading);
            },
            fetchedData(newFetchedData) {
                this.$emit('update:fetchedData', newFetchedData);
            },
            endpoint() {
                this.fetchData();
            },
            thereIsNoData(newThereIsNoData) {
                this.$emit('update:thereIsNoData', newThereIsNoData);
            },
        }
    }
</script>
