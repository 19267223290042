<template>
    <div class="text-center">
        <p class="my-5">
            <i class="fa fa-calendar fa-4x text-info" aria-hidden="true"></i>
        </p>

        <h2 class="h6 mb-3 mt-5">Click on create post to start</h2>
    </div>
</template>

<script>
    export default {
        name: 'GeneratedPostEmpty',
    }
</script>
