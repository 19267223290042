<template>
    <div>
        <div v-if="itemsLoading" class="text-center">
            <b-spinner
                class="m-5"
                variant="success"
                :label='$t("connection.state")'>
            </b-spinner>
        </div>

        <div v-else>
            <component
                v-for="(item, index) in items" :key="index"
                :is="itemComponent"
                :item="item"
                @delete-item="refreshList"
                @add-item="refreshList"
                >
            </component>

            <component
                v-if="noResults && emptyComponent"
                :is="emptyComponent"
                >
            </component>

            <slot empty>
                <div
                    v-if="noResults && emptyText"
                    class="d-flex justify-content-center align-items-center text-muted"
                >
                    {{ emptyText }}
                </div>
            </slot>

            <b-pagination
                v-if="showPaginator && pageTotalRows > pageSize"
                v-model="page"
                :total-rows="pageTotalRows"
                :per-page="pageSize"
                >
            </b-pagination>
        </div>
    </div>
</template>

<script>
    import { debounce } from '@/utils/timing';
    import axios from '@/setup/axios-setup.js';
    import Toast from '@/toast.js';
    const toast = new Toast();
    const DEFAULT_DEBOUNCE_MS = 300;

    export default {
        name: 'ItemList',
        emits: ['refresh'],
        props: {
            endpoint: {
                type: String,
                required: true
            },
            endpointDebounceDelay: {
                type: Number,
                default: DEFAULT_DEBOUNCE_MS,
            },
            itemComponent: {
                type: Object,
                required: true
            },
            emptyComponent: {
                type: Object,
                required: false,
            },
            emptyText: {
                type: String,
                required: false,
            },
            showPaginator: {
                type: Boolean,
                default: true
            },
            pageSize: {
                type: Number,
                default: 20
            }
        },
        data: function () {
            return {
                callEndpointDebounced: debounce(this.callEndpoint, this.$props.endpointDebounceDelay),
                items: [],
                itemsLoading: false,
                pageTotalRows: 0,
                page: 1,
            }
        },
        methods: {
            callEndpoint() {
                this.items = [];
                axios.get(this.apiURL).then(response => {
                    this.itemsLoading = false;
                    this.items = response.data.results || [];
                    this.pageTotalRows = response.data.count;
                }).catch( () => {
                    toast.error("Unable to get items!", 'Error');
                    this.itemsLoading = false;
                });
            },
            refreshList(){
                this.$emit('refresh');
                this.loadItems();
            },
            loadItems(){
                if (!this.itemsLoading) {
                    this.itemsLoading = true;
                }
                this.callEndpointDebounced();
            },
            cleanItems(){
                this.items = [];
            }
        },
        computed: {
            apiURL(){
                // Check if URL already contains parameters
                const hasParams = this.endpoint.includes('?');
                // Append parameter using appropriate separator
                const separator = hasParams ? '&' : '?';
                // Construct the modified URL
                return `${this.endpoint}${separator}page=${this.page}`;
            },
            noResults() {
                return !this.itemsLoading && this.items && this.items.length === 0;
            }
        },
        watch: {
            endpoint() {
                if (this.page !== 1) {
                    this.page = 1;
                } else {
                    this.loadItems();
                }
            },
            page: 'loadItems',
        },
        mounted: function () {
            this.loadItems();
        },
    }
</script>
