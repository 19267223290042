<template>
    <confirm-and-wait-modal
        okTitle="Schedule"
        title="Schedule Post"
        cancel-variant="light"
        :ok-disabled="!isFormOk"
        method="post"
        :params="form">
        <div>
            <b-form>
                <b-form-group label="Social Networks:" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                    id="socialnetwork"
                    :aria-describedby="ariaDescribedby"
                    name="socialnetwork">
                        <b-form-checkbox value="facebook" v-if="profile.applications.facebook.is_enabled" v-model="form.applications">
                            <i class="fa fa-facebook"></i>
                            Facebook
                        </b-form-checkbox>
                        <b-form-checkbox value="instagram" v-if="profile.applications.instagram.is_enabled" v-model="form.applications">
                            <i class="fa fa-instagram"></i>
                            Instagram
                        </b-form-checkbox>
                        <b-form-checkbox value="linkedin" v-if="profile.applications.linkedin.is_enabled" v-model="form.applications">
                            <i class="fa fa-linkedin"></i>
                            LinkedIn
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>
                <date-picker v-model:value="form.fire_at"></date-picker>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import DatePicker from '../../common/DatePicker.vue';
    import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';

    export default {
        name: 'GeneratedPostScheduleModal',
        props: {
            post:{
                type: Object,
                required: true
            }
        },
        components: {
            ConfirmAndWaitModal,
            DatePicker,
        },
        data() {
            return {
                profile: window.profileData,
                form: {
                    applications: null,
                    fire_at: null,
                },
                timeConfirm: false,
            }
        },
        computed: {
            isFormOk(){
                return this.form.applications && this.form.applications.length > 0 && this.form.fire_at
            }
        },
        methods: {
            updateModelValue($event){
                this.form.fire_at = $event
            }
        }
    };
</script>
