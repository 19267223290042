<template>
    <li><a :href="item.url" target="_blank">{{ item.title }}</a></li>
</template>

<script>
    export default {
        name: 'LandingPageItem',
        props: {
            item: {
                type: Object,
                required: true
            }
        }
    }
</script>
