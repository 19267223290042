<template>
    <confirm-and-wait-modal
        okTitle="Create"
        successMessage="Created!"
        title="Create new post"
        cancel-variant="light"
        method="post"
        ok-variant="success"
        :ok-disabled="!isFormOk"
        :params="form">
        <div>
            <b-form>
                <b-form-group
                    description="Enter the topic of your post. Example: caribbean beach, alaska aurora"
                    label="Post Topic"
                    label-for="query">
                    <b-form-input
                        id="query"
                        required
                        v-model="form.query"
                        placeholder="Enter your post query"
                    ></b-form-input>
                </b-form-group>
            </b-form>
        </div>

    </confirm-and-wait-modal>
</template>

<script>
import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';
export default {
  components: { ConfirmAndWaitModal },
    name: 'GeneratedPostCreateModal',
    props: {
        post:{
            type: Object,
            required: true
        },
    },
    data() {
        return {
            form: {
                query: ''
            }
        }
    },
    computed: {
        isFormOk(){
            return this.form.query
        }
    }
};
</script>
