<template>
    <confirm-and-wait-modal
        :id="id"
        :ok-disabled="!isFormOk"
        :ok-title="$t('okTitle')"
        :params="{...params, ...form}"
        :successMessage="$t('success')"
        :title="$t('title')"
        endpoint="/api/contacts/"
        method="post"
    >
        <div>
            <b-form v-if="show">
                <b-form-group>
                    <b-form-input
                        :placeholder="$t('placeholders.email')"
                        id="contact-input-email"
                        required
                        type="email"
                        v-model="form.email"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        :placeholder="$t('placeholders.firstName')"
                        id="contact-input-first-name"
                        required
                        v-model="form.first_name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        :placeholder="$t('placeholders.lastName')"
                        id="contact-input-last-name"
                        required
                        v-model="form.last_name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox
                        id="agreement-chk"
                        required
                        v-model="form.agreement_chk"
                        ><small>{{ $t('agreementChk') }}</small>
                    </b-form-checkbox>
                </b-form-group>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';
    export default {
        components: { ConfirmAndWaitModal },
        props: {
            id: {
                type: String,
                default: 'contact-add-modal'
            },
            contactListId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    agreement_chk: false,
                },
                show: true,
                params: {
                    contact_list: this.contactListId,
                    emarketing: window.profileData.pk,
                }
            }
        },
        methods: {
            getParams() {
                this.params ={
                    contact_list: this.contactListId,
                    emarketing: window.profileData.pk,
                }
            }
        },
        computed: {
            isFormOk() {
                const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
                const { first_name, last_name, email, agreement_chk } = this.form
                return first_name && last_name && email && emailRegex.test(email) && agreement_chk
            }
        },
        mounted: function () {
            document.addEventListener('DOMContentLoaded', () => {
                this.getParams();
            }, { once : true });
        },
        i18n: {
            messages: {
                en: {
                    placeholders: {
                        email: "Email",
                        firstName: "First name",
                        lastName: "Last name",
                    },
                    title: "Add a new contact",
                    okTitle: "Add contact",
                    success: "Contact added",
                    agreementChk: "I confirm that I have obtained consent from the contacts I am uploading to receive emails from me. I understand that I am solely responsible for ensuring compliance with spam laws and will be held liable for any failure to obtain proper consent.",
                },
                es: {
                    placeholders: {
                        email: "Email",
                        firstName: "Nombre",
                        lastName: "Apellido",
                    },
                    title: "Agregar un nuevo contacto",
                    okTitle: "Agregar",
                    success: "Contacto agregado",
                    agreementChk: "Confirmo que he obtenido el consentimiento de los contactos que estoy cargando para recibir correos electrónicos míos. Entiendo que soy el único responsable de garantizar el cumplimiento de las leyes sobre spam y que seré considerado responsable en caso de no obtener el consentimiento adecuado.",
                },
                fr: {
                    placeholders: {
                        email: "Email",
                        firstName: "Nom",
                        lastName: "Le nom",
                    },
                    title: "Ajouter un nouveau contact",
                    okTitle: "Ajouter",
                    success: "Contact ajouté",
                    agreementChk: "Je confirme que j'ai obtenu le consentement des contacts que je télécharge pour recevoir des courriels de ma part. Je comprends que je suis seul responsable du respect de la législation sur les spams et que je serai tenu pour responsable de tout manquement à l'obligation d'obtenir un consentement adéquat.",
                },
            }
        }
    }
</script>
