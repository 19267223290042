<template>
    <b-card class="text-center b-card" :deck="true" border-variant="none">
        <i :class="['fa', icon, 'fa-3x', 'icon']"></i>
        <div class="label">{{ label }}</div>
        <div class="value">{{ value }}</div>
    </b-card>
</template>

<script>
export default {
    name: 'StatCard',
    props: {
        icon: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            required: true,
        },
    },
};
</script>

<style scoped>
.icon {
    color:rgb(29, 163, 73); /* Green color */
    margin-bottom: 10px;
}

.label {
    background: linear-gradient(to bottom, #007a33, #00a053); /* Green gradient */
    color: white;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 15px; /* Shape similar to the "Agents" button */
    margin-top: 10px;
    margin-bottom: 10px;
}

.value {
    color: rgb(29, 163, 73); /* Green color */
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 5px;
}

.b-card {
    border: none; /* Remove border */
    box-shadow: none; /* Remove box shadow */
    width: 100%;
}

</style>
