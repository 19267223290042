<template>
    <confirm-and-wait-modal
        :endpoint="`/api/scheduled_postings/${item.id}/`"
        :id="`reschedulePost${item.id}`"
        :params="this.params"
        :title="this.$t('post.editDateTitle')"
        method="patch">
        <date-picker v-model:value="dd" :placeholder="this.$t('common.selectDate')"></date-picker>
        <div v-if="profileCount > 1" class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="post-reschedule-apply-to-all"
                v-model="applyToAll">
            <label class="form-check-label" for="post-reschedule-apply-to-all">
                {{ $t("post.actions.applyAll") }}
            </label>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import ConfirmAndWaitModal from '../common/ConfirmAndWaitModal.vue';
    import DatePicker from '@/components/common/DatePicker.vue';
    import { formatDate } from '../../utils/datetime.js';

    export default {
        name: 'PostActionsReschedule',
        components: {
            ConfirmAndWaitModal,
            DatePicker
        },
        props: {
            post: {
                type: Object,
                required: true
            },
            profileCount: {
                type: Number,
                required: true
            }
        },
        data: function () {
            return {
                applyToAll: false,
                dd: this.$props.post.fire_at.toISOString(),
                item: this.$props.post,
                params: {
                    fire_at: this.$props.post.fire_at,
                    apply_to_all: false
                }
            };
        },
        watch: {
            dd: function(newVal) {
                this.params.fire_at = formatDate.longISO(newVal);
            },
            applyToAll: function(newVal) {
                this.params.apply_to_all = newVal
            }
        }
    }
</script>
