<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        :id="id"
        :okTitle="$t('okTitle')"
        :params="{ ...params, ...form }"
        :successMessage="$t('success')"
        :title="$t('title')"
        method="put"
    >
        <div>
            <b-form v-if="show">
                <b-form-group>
                    <b-form-input
                        disabled
                        id="contact-input-email"
                        :placeholder="$t('placeholders.email')"
                        required
                        type="email"
                        v-model="form.email"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="contact-input-first-name"
                        :placeholder="$t('placeholders.firstName')"
                        required
                        v-model="form.first_name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        :placeholder="$t('placeholders.lastName')"
                        id="contact-input-last-name"
                        required
                        v-model="form.last_name"
                    ></b-form-input>
                </b-form-group>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';
    export default {
        components: { ConfirmAndWaitModal },
        props: {
            id: {
                type: String,
                default: 'contact-edit-modal'
            },
            contact: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    email: '',
                    first_name: '',
                    last_name: '',
                },
                show: true,
                endpoint: '',
                params: {
                    contact_list: this.contactListId,
                    emarketing: window.profileData.pk,
                }
            }
        },
        methods: {
            init(contact) {
                this.params = {
                    contact_list: this.contactListId,
                    emarketing: window.profileData.pk,
                }
                const { id, email, first_name, last_name } = contact || {};
                this.form.email = email;
                this.form.first_name = first_name;
                this.form.last_name = last_name;
                this.endpoint = `/api/contacts/${id}/`;
            }
        },
        watch: {
            contact: {
                handler(newContact) {
                    this.init(newContact)
                },
                immediate: true
            }
        },
        i18n: {
            messages: {
                en: {
                    placeholders: {
                        email: "Email",
                        firstName: "First name",
                        lastName: "Last name",
                    },
                    title: "Edit contact",
                    okTitle: "Save",
                    success: "Changes saved",
                },
                es: {
                    placeholders: {
                        email: "Email",
                        firstName: "Nombre",
                        lastName: "Apellido",
                    },
                    title: "Editar contacto",
                    okTitle: "Guardar",
                    success: "Cambios guardados",
                },
                fr: {
                    placeholders: {
                        email: "Email",
                        firstName: "Nom",
                        lastName: "Le nom",
                    },
                    title: "Modifier le contact",
                    okTitle: "Sauvegarder",
                    success: "Changements sauvegardés",
                },
            },
        },
    }
</script>
