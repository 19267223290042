<template>
    <div>
        <div ref="quillEditor" :style="editorStyle"></div>
    </div>
</template>

<script>
export default {
    name: 'RichTextEditor',
    props: {
        content: String,
        rows: {
            type: Number,
            default: 5
        }
    },
    mounted() {
        this.editor = new window.Quill(this.$refs.quillEditor, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: [
                        ['link'],
                        ['bold', 'italic'],
                        ['undo', 'redo'] // Custom buttons
                    ],
                    handlers: {
                        'undo': () => {
                            this.editor.history.undo();
                        },
                        'redo': () => {
                            this.editor.history.redo();
                        }
                    }
                },
                history: {
                    delay: 2000,
                    maxStack: 500,
                    userOnly: true
                }
            }
        });
        if (this.content) {
            this.editor.root.innerHTML = this.content;
        }

        this.editor.on('text-change', () => {
            this.$emit('update:content', this.editor.root.innerHTML);
        });
        this.addClassToElements('ql-undo', ['fa', 'fa-undo']);
        this.addClassToElements('ql-redo', ['fa', 'fa-repeat']);
    },
    methods: {
        // quick hack to stylize undo and redo buttons in quill
        addClassToElements(targetClass, newClasses) {
            const elements = this.$el.querySelectorAll(`.${targetClass}`);
            elements.forEach(element => {
                newClasses.forEach(newClass => {
                    element.classList.add(newClass);
                })
            });
        }
    },
    computed: {
        editorStyle() {
            return { minHeight: `${this.$props.rows * 1.8}em` };
        }
    },
    watch: {
        content(newContent) {
            // if the incoming content changes, update immediately
            if (newContent !== this.editor.root.innerHTML) {
                this.editor.root.innerHTML = newContent;
            }
        }
    },
};
</script>

<style scoped>
    @import "quill/dist/quill.core.css";
    @import "quill/dist/quill.snow.css";
</style>
