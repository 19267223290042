<template>
    <confirm-and-wait-modal
        :endpoint="`/api/scheduled_postings/${post.id}/`"
        :id="`editPost${post.id}`"
        :params="this.params"
        :title="this.$t('post.editTextTitle')"
        method="patch">
        <div class="form-group">
            <label for="comment">{{ $t("common.message") }}:</label>
            <textarea
                id="post-message"
                class="form-control"
                rows="5"
                v-model="item.message"></textarea>
        </div>
        <div v-if="profileCount > 1" class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="post-message-apply-to-all"
                v-model="params.apply_to_all">
            <label class="form-check-label" for="post-message-apply-to-all">
                {{ $t("post.actions.applyAll") }}
            </label>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import ConfirmAndWaitModal from '../common/ConfirmAndWaitModal.vue';

    export default {
        name: 'PostActionsEdit',
        components: {
            ConfirmAndWaitModal,
        },
        props: {
            post: {
                type: Object,
                required: true
            },
            profileCount: {
                type: Number,
                required: true
            }
        },
        data: function () {
            return {
                item: this.$props.post,
                params: {
                    message: this.$props.post.message,
                    apply_to_all: false
                }
            };
        },
        watch: {
            'item.message': function(newVal) {
                this.params.message = newVal
            }
        }
    }
</script>
