<template>
    <div class="d-flex justify-content-between w-75 mx-auto my-5">
        <div class="connection-box">
            <img src="https://assets.branchup.travel/BranchupApp/logo.png" alt="" width="55">
        </div>
        <div class="d-flex align-items-center flex-grow-1 text-danger">
            <span class="connection-broken__line"></span>
            <span class="connection-broken__icon">
                <i class="fa fa-lg fa-times" aria-hidden="true"></i>
            </span>
            <span class="connection-broken__line connection-broken__line--dashed"></span>
        </div>
        <div class="connection-box connection-box--disabled connection-box--column">
            <i
                :class="`fa-${app} text-${app}`"
                class="fa fa-lg"
                aria-hidden="true">
            </i>
            <span class="text-capitalize text-muted mt-1">{{ app }}</span>
        </div>
    </div>
</template>

<script>
    import { appStringProp } from '@/utils/props.js';

    export default {
        name: 'ConnectionBroken',
        props: {
            app: appStringProp,
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/_variables.scss";
    .connection-box {
        align-items: center;
        border-radius: 15px;
        border: 3px solid $primary;
        display: inline-flex;
        justify-content: center;
        padding: 15px;

        &--disabled {
            border-color: $dorian;
        }

        &--column {
            flex-direction: column;
        }
    }

    .connection-broken {
        &__icon {
            align-items: center;
            border-radius: 50%;
            border: 3px solid $danger;
            display: flex;
            height: 35px;
            justify-content: center;
            text-align: center;
            width: 35px;
        }

        &__line {
            height: 3px;
            border-bottom: 3px solid $danger;
            flex-grow: 1;

            &--dashed {
                border: 2px dashed $danger;
            }
        }
    }
</style>
