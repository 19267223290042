<template>
    <endpoint-fetcher :endpoint="endpoint" v-model:fetchedData="endpointData">
        <div>
            <h5 v-if="supplier">Newsletter Performance for {{ supplier.supplier_name }}</h5>
            <h5 v-else>Newsletter Performance</h5>

            <b-container fluid>
                <b-row>
                    <b-col xs="12" lg="6">
                        <funnel-chart useRawValues :data="performanceData" :colors="colors" />
                    </b-col>
                    <b-col xs="12" lg="6">
                        <b-table :items="performanceData" :fields="performanceFields">
                            <template v-slot:cell(label)="row">
                                <div :style="{ color: colors[row.index], fontWeight: 'bold' }">
                                    {{ row.item.label }}
                                </div>
                            </template>
                            <template v-slot:cell(count)="row">
                                <div
                                    :style="{
                                        color: colors[row.index],
                                        fontWeight: 'bold',
                                        textAlign: 'right'
                                    }"
                                >
                                    {{ numToString(row.item.value) }}
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-container>

            <b-container fluid v-if="showTopStats">
                <b-row>
                    <b-col>
                        <top-stats
                            title="Biggest Audience"
                            :valueFormatter="numToString"
                            :fields="topFields"
                            :items="topAudienceItems"
                        ></top-stats>
                    </b-col>
                    <b-col>
                        <top-stats
                            title="Most Opens"
                            :valueFormatter="numToString"
                            :fields="topFields"
                            :items="topOpenItems"
                        ></top-stats>
                    </b-col>
                    <b-col>
                        <top-stats
                            title="Most Clicks"
                            :valueFormatter="numToString"
                            :fields="topFields"
                            :items="topClickItems"
                        ></top-stats>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </endpoint-fetcher>
</template>

<script>
    import EndpointFetcher from '@/components/common/EndpointFetcher';
    import FunnelChart from '@/components/common/FunnelChart';
    import TopStats from '@/components/common/TopStats';
    import { numToString } from '@/utils/numbers';
    import { routes } from './campaigns-config';

    export default {
        props: {
            campaignId: {
                type: String,
                required: true,
            },
            supplier: {
                type: Object,
                required: true,
            },
        },
        components: {
            EndpointFetcher,
            FunnelChart,
            TopStats
        },
        data() {
            return {
                colors: ["#718096", "#037C03", "#3182CE"],
                endpointData: null,
                topFields: [
                    { key: 'name', label: 'Name', thStyle: { display: 'none' } },
                    { key: 'value', label: 'Value', thStyle: { display: 'none' } },
                ],
                performanceFields: [
                    { key: 'label', label: 'Stat', thStyle: { display: 'none' }, formatter: numToString },
                    { key: 'count', label: 'Count', thStyle: { display: 'none' }, formatter: numToString },
                ],
            }
        },
        methods: {
            numToString, // make this available to template
        },
        computed: {
            endpoint() {
                const supplierId = this.supplier ? this.supplier.pk : undefined;
                return routes.stats(this.$props.campaignId, supplierId);
            },
            performanceData() {
                let performanceData = [];
                if (this.endpointData) {
                    const { num_sent, num_open, num_click } = this.endpointData.summary;
                    performanceData = [
                        { label: 'DELIVERED', value: num_sent},
                        { label: 'OPENED', value: num_open},
                        { label: 'CLICKED', value: num_click },
                    ];
                }
                return performanceData;
            },
            showTopStats() {
                return this.endpointData && this.endpointData.summary.num_agent > 1;
            },
            topAudienceItems() {
                return this.endpointData ? this.endpointData.top.audience : []
            },
            topClickItems() {
                return this.endpointData ? this.endpointData.top.clicks : []
            },
            topOpenItems() {
                return this.endpointData ? this.endpointData.top.opens : []
            },
        },
    }
</script>

<style scoped>
    .small-text {
        font-size: small;
    }
</style>
