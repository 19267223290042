<template>
    <div class="content">
        <div class="row mb-3">
            <div class="col-md-3 col-sm-12">
                <input type="text" v-model="filteredText" :placeholder="$t('landingpages.filterText')" class="h-100 w-100 pl-2">
            </div>
            <div class="col-md-3 col-sm-12">
                <select v-model="filteredSupplier" class="form-control">
                    <option value="">{{ $t("landingpages.selectSupplier") }}</option>
                    <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{
                    supplier.supplier_label }}</option>
                </select>
            </div>
            <div class="col-md-1 col-sm-6">
                <button class="btn btn-light btn-outline-dark mr-2" @click="applyFilters">{{ $t("common.search") }}</button>
            </div>
            <div class="col-md-1 col-sm-6">
                <button class="btn" @click="clearFilters">{{ $t("common.clear") }}</button>
            </div>
        </div>
        <div v-if="isFetchingContent" class="row">
            <div class="col-12">
                <b-spinner class="m-5" variant="success" :label='$t("connection.state")'>
                </b-spinner>
            </div>
        </div>
        <div v-if="landingPages.length && !isFetchingContent" class="row">
            <div v-for="(landingPage, index) in landingPages" :key="index" class="col-lg-6 col-12 mb-4">
                <landing-page-card :landing="landingPage"></landing-page-card>
            </div>
            <div class="col-12">
                <b-pagination :total-rows="totalRows" align="center"
                    per-page="20" size="sm" v-model="currentPage"></b-pagination>
            </div>
        </div>
        <div v-if="!landingPages.length && !isFetchingContent" class="row">
            <div class="col-12">
                <h3>{{ $t("landingpages.noLandingPagesFound") }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '../../setup/axios-setup';
    import { endpoints } from './api/EndpointsConfig';
    import LandingPageCard from './LandingPageCard.vue';
    const profile = window.profileData;

    export default {
        name: 'LandingPagesSearch',
        components: {
            LandingPageCard
        },
        data: function () {
            return {
                isFetchingContent: true,
                filteredSupplier: "",
                filteredText: "",
                dateRange: { startDate: null, endDate: null },
                currentPage: 1,
                totalRows: 1,
                landingPages: {},
                suppliers: {},
                profilePk: profile.pk
            };
        },
        methods: {
            getLandingPages() {
                this.isFetchingContent = true;
                axios.get(
                    endpoints.landing_pages(
                        profile.pk,
                        this.currentPage,
                        this.filteredSupplier,
                        this.filteredText
                    ))
                    .then((response) => {
                        this.landingPages = response.data.results;
                        this.totalRows = response.data.count;
                    })
                    .finally(() => {
                        this.isFetchingContent = false;
                    });
            },
            getSuppliers() {
                axios.get(
                    endpoints.suppliers_filtered_list(
                        profile.pk
                    ))
                    .then((response) => {
                        this.suppliers = response.data;
                    });
            },
            applyFilters() {
                this.currentPage = 1;
                this.getLandingPages();
            },
            clearFilters() {
                this.filteredSupplier = "";
                this.filteredText = "";
                this.currentPage = 1;
                this.getLandingPages();
            }
        },
        watch: {
            currentPage: {
                handler: function () {
                    this.getLandingPages();
                }
            },
        },
        mounted() {
            this.getLandingPages();
            this.getSuppliers();
        }
    }
</script>
