<template>
    <div class="app-heading mb-3">
        <i class="fa app-heading__icon mr-1" :class="icon"></i>
        <a class="app-heading__link" :href="url" target="_blank">
            <small>{{ label }}</small>
        </a>
    </div>
</template>
<script>
    import { connectionConfigValues } from '@/components/apps-connection-config.js';
    import { appStringProp } from '@/utils/props.js';

    export default {
        name: 'SocialLink',
        props: {
            app: appStringProp,
        },
        computed: {
            application(){
                return window.profileData.applications[this.app];
            },
            url(){
                if(this.application){
                    return this.application.data.url ? this.application.data.url : '';
                }
                return '';
            },
            label(){
                if(this.application){
                    return this.application.data.name ? this.application.data.name : this.application.name;
                }
                return '';
            },
            icon(){
                return connectionConfigValues[this.app].icon;
            }
        }
    }
</script>
