<template>
    <ul>
        <li>{{ $t('email') }}: {{ contact.email }}</li>
        <li>{{ $t('firstName') }}: {{ contact.first_name }}</li>
        <li>{{ $t('lastName') }}: {{ contact.last_name }}</li>
    </ul>
</template>

<script>
    export default {
        name: 'contact-card',
        props: {
            contact: {
                type: Object,
                required: true,
            },
        },
        data: function () {
            return {}
        },
        i18n: {
            messages: {
                en: {
                    email: "Email",
                    firstName: "First name",
                    lastName: "Last name",
                },
                es: {
                    email: "Email",
                    firstName: "Nombre",
                    lastName: "Apellido",
                },
                fr: {
                    email: "Email",
                    firstName: "Nom",
                    lastName: "Le nom",
                },
            }
        }
    }
</script>
