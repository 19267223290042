<template>
    <div class="event-card card" :class="enabledClass">
        <div class="d-flex justify-content-between mt-1" :class="`text-${eventInfo.app}`">
            <span class="event-card-time ml-1">{{ event.timeText }}</span>
            <span class="mr-1">
                <i class="fa mr-1" :class="statusIcon"></i>
                <i class="fa" :class="icon"></i>
            </span>
        </div>
        <span class="event-card-title ml-1 mr-1">{{ event.event.title }}</span>
        <img :src="eventInfo.imageUrl" class="event-card-image p-1" />
    </div>
</template>

<script>
    import { connectionConfigValues } from '@/components/apps-connection-config.js';
    export default {
        name: 'CalendarEvent',
        props: {
            event: {
                type: Object,
                required: true,
            }
        },
        computed: {
            eventInfo() {
                return this.event.event.extendedProps
            },
            enabledClass() {
                return this.event.event.extendedProps.enabled ? 'event-card-enabled' : 'event-card-disabled'
            },
            icon() {
                return connectionConfigValues[this.eventInfo.app].icon;
            },
            statusIcon() {
                let icon = '';
                const status = this.event.event.extendedProps.post.status;
                // Deleted
                if([30, 40, 45, 50].includes(status)) {
                    icon = 'fa-trash';
                }
                // Pending
                if(status === 0 || status === 'pending') {
                    icon = 'fa-clock-o';
                }
                // Posted
                if(status === 10 || status === 'published') {
                    icon = 'fa-send';
                }
                return icon;
            },
        }
    }
</script>
