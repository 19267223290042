<template>
    <div class="funnel-chart">
        <div v-for="(item, index) in sizedData" :key="index" class="funnel-item">
            <div
                class="funnel-bar"
                :style="{
                    background: getBackground(index, item.value),
                    width: `${item.__w}%`
                }"
            >
                <span :style="getItemStyle(item, index)">
                    {{ printValue(item.value) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { numToPercentage, numToString } from '@/utils/numbers';
const MIN_WIDTH_PERCENTAGE = 20;

export default {
    props: {
        useRawValues: {
            type: Boolean,
            required: false
        },
        data: {
            type: Array,
            required: true
        },
        colors: {
            type: Array,
            default: () => [
                '#3eb937', '#5db201', '#76aa00', '#8da000', '#a29500',
                '#b68800', '#cb7700', '#df6200', '#f04500', '#ff0000',
            ]
        },
        dynamicColors: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        getBackground(index, value, defaultColor=null) {
            /** chooses a color based on the percentage value, and renders a linear gradient from slightly
             * darker to slightly lighter only for aestethics.
            */
            let color = defaultColor || this.getColor(index, value)

            const r = parseInt(color.substring(1, 3), 16);
            const g = parseInt(color.substring(3, 5), 16);
            const b = parseInt(color.substring(5, 7), 16);

            // Calculate slightly darker and lighter colors
            const variationIndex = 5;

            const darkerR = Math.max(0, r - variationIndex);
            const darkerG = Math.max(0, g - variationIndex);
            const darkerB = Math.max(0, b - variationIndex);
            const darkerColor = `rgb(${darkerR}, ${darkerG}, ${darkerB})`;

            const lighterR = Math.min(255, r + variationIndex);
            const lighterG = Math.min(255, g + variationIndex);
            const lighterB = Math.min(255, b + variationIndex);
            const lighterColor = `rgb(${lighterR}, ${lighterG}, ${lighterB})`;

            // Create a linear gradient background
            return `linear-gradient(to bottom, ${darkerColor} 0%, ${color} 50%, ${lighterColor} 100%)`;
        },
        getColor(index, value) {
            if (this.dynamicColors) {
                // Assigns a color based on value percentage representation
                const maxIndex = this.colors.length - 1;
                const percentage = value / 100;
                const index = Math.round((1 - percentage) * maxIndex);
                // Ensure the index stays within the array bounds
                const finalIndex = Math.max(0, Math.min(index, maxIndex));
                return this.colors[finalIndex];
            } else {
                return this.colors[index]
            }
        },
        getItemStyle(item, index) {
            const width = item.__w;
            if (width == 0) {
                return { color: this.colors[index]}
            } else if (width < MIN_WIDTH_PERCENTAGE) {
                return {
                    'position': 'absolute',
                    'left': `${50 + width}%`,
                    'color': this.colors[index],
                    'padding-right': '15px'
                };
            } else {
                return {};
            }
        },
        printValue(value) {
            return this.useRawValues ? numToString(value) : numToPercentage(value);
        },
        getWidth(value) {
            // get width % based on existing values
            const result = Math.round(value/this.maxValue*100);
            return result;
        },
    },
    computed: {
        maxValue() {
            // consider if values exceed 100%
            const maxValue = Math.max(...this.data.map(d => d.value));
            return this.useRawValues ? maxValue : maxValue > 100 ? maxValue : 100;
        },
        sizedData() {
            return this.data.map(item => ({ ...item, __w: this.getWidth(item.value)}));
        }
    }
};
</script>

<style scoped>
    .funnel-chart {
        display: flex;
        flex-direction: column;
    }
    .funnel-item {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 0.2em;
    }
    .funnel-bar {
        align-items: center;
        border-radius: 0.1em;
        color: white;
        display: flex;
        font-size: larger;
        font-weight: bold;
        height: 2.4em;
        justify-content: center;
    }
</style>
