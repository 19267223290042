import moment from 'moment-timezone';

export const fields = Object.freeze([
    { key: 'email', label: 'Email', sortable: true },
    { key: 'first_name', label: 'First name', sortable: true },
    { key: 'last_name', label: 'Last name', sortable: true },
    {
        key: 'optout_status',
        label: 'Status',
        sortable: true,
        formatter: value => {
            switch (value) {
                case 'F': return 'Opted in';
                case 'T': return 'Opted out';
                case 'U': return 'Undeliverable';
                default: return '';
            }
        }
    },
    {
        key: 'optout_date',
        label: "Status date",
        sortable: true,
        formatter: value => {
            if (value) {
                return moment.tz(value, "America/Toronto").local().format("L");
            } else {
                return ''
            }
        }
    },
    { key: 'actions', label: "Actions", class: 'text-center' }
]);

export const statusOptions = Object.freeze([
    { value: '', text: 'All' },
    { value: 'F', text: 'Opted in' },
    { value: 'T', text: 'Opted out' },
    { value: 'U', text: 'Undeliverable' },
]);
