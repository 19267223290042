const getLAndingPageListAPI = (profile, page=null, supplier=null, text=null) => {
    const pageParam = page ? `&page=${page}` : '';
    const supplierParam = supplier ? `&supplier=${supplier}` : ''
    const textParam = text ? `&text=${text}` : ''
    const params = [pageParam, supplierParam, textParam].filter(p => p).join('')
    return `/api/landing_pages/?profile=${profile}${params}`;
}

export const endpoints = {
    landing_pages: getLAndingPageListAPI,
    suppliers_filtered_list: (profile) => `/api/suppliers/filtered_list?profile_id=${profile}`,
}
