<template>
  <div class="card">
    <a v-if="landing.image" class="card-img-a" :href="landing.url" target="_blank">
        <img class="card-img-top" :src="landing.image" alt="Card image cap">
    </a>
    <div class="card-body">
        <div class="card-title"><h5>{{ landing.title }}</h5></div>
        <div class="mb-3">
            <button @click="copyText(landing.url)" class="btn mr-2 btn-light"><i class="fa fa-copy"></i></button>
            <a :href="landing.url" target="_blank">{{ landing.url }}</a>
        </div>
        <div class="clearfix"></div>
        <div class="mb-3">
            <i class="fa fa-suitcase"></i>
            {{ landing.supplier_name }}
        </div>
        <div class="clearfix"></div>
        <div class="mb-3">
            <i class="fa fa-calendar"></i>
            {{ $t("landingpages.publishedOn") }} {{ landing.publishing_date }}
        </div>
        <div class="clearfix"></div>
        <div class="mb-3">
            <i class="fa fa-calendar"></i>
            {{ $t("landingpages.expiresOn") }} {{ landing.expiration_date }}
        </div>
    </div>
  </div>
</template>

<script>
  import Toast from '../../toast.js';
  const toast = new Toast();

  export default {
    props: {
      landing: {
        type: Object,
        required: true
      },
    },
    methods: {
      copyText(textToCopy) {
        try {
          navigator.clipboard.writeText(textToCopy);
          toast.success(this.$t("landingpages.urlCopied"), this.$t("landingpages.copied"));
        } catch (error) {
          toast.error(this.$t("landingpages.urlNotCopied"), this.$t("common.error"));
        }
      }
    }
  }
</script>
