<template>
    <a class="reset-link" :href="planUrl" v-if="profile.plan === 'free'">
        <span :style="textStyle">Upgrade to </span>
        <plan-badge class="ml-1" plan="pro" :size="size"></plan-badge>
    </a>
</template>

<script>
    import { views } from '@/components/common/views-config.js';
    import PlanBadge from '../common/PlanBadge.vue';

    const FONTSIZES = Object.freeze({
        lg: '14px',
        xl: '16px',
    });

    export default {
        name: 'upgrade-to-premium-link',
        props: {
            size: {
                type: String,
                default: 'lg',
                validator(value) {
                    return ['', 'lg', 'xl'].includes(value);
                }
            }
        },
        components: {
            PlanBadge
        },
        computed: {
            profile() {
                return window.profileData;
            },
            planUrl() {
                return views['profile_plan'](this.profile.slug);
            },
            textStyle(){
                return `font-size: ${FONTSIZES[this.size]}`;
            }
        }
    }
</script>
