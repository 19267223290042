export const getQueryParams = (params={}, skipUndefined=true) => {
    /** receives a params dictionary like { param1: value1, param2: value2 } and returns the corresponding
     *  query params: "?param1=value1&param2=value2"
     *  If there are no params returns an empty string
     */
    let paramArray = [];
    if (skipUndefined) {
        paramArray = Object.entries(params).filter(e => ![null, undefined].includes(e[1]))
    } else {
        paramArray = Object.entries(params)
    }
    if (paramArray.length) {
        return '?' + paramArray.map(entry => entry.join('=')).join('&');
    } else {
        return '';
    }
}

export const joinURL = (pathSegments=[]) => {
    const nonNullSegments = pathSegments.filter(s => s);
    return nonNullSegments.length ? `/${nonNullSegments.join('/')}/` : '';
}
