import axios from 'axios';

axios.interceptors.request.use(config => {
    const { ignoreCsrfToken } = config;
    const csrftoken = window.csrftoken;
    if (!ignoreCsrfToken && csrftoken) {
        config.headers['X-CSRFToken'] = csrftoken;
    }
    return config;
});

export default axios;
