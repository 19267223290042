export const routes = {
    generator: (profile) => `/${profile}/generator/posts/`,
}

export const voiceToneOptions = [
    {'value': "default", 'text': "Default"},
    {'value': "authoritative", 'text': "Authoritative"},
    {'value': "caring", 'text': "Caring"},
    {'value': "casual", 'text': "Casual"},
    {'value': "cheerful", 'text': "Cheerful"},
    {'value': "coarse", 'text': "Coarse"},
    {'value': "conservative", 'text': "Conservative"},
    {'value': "conversational", 'text': "Conversational"},
    {'value': "creative", 'text': "Creative"},
    {'value': "dry", 'text': "Dry"},
    {'value': "edgy", 'text': "Edgy"},
    {'value': "enthusiastic", 'text': "Enthusiastic"},
    {'value': "expository", 'text': "Expository"},
    {'value': "formal", 'text': "Formal"},
    {'value': "frank", 'text': "Frank"},
    {'value': "friendly", 'text': "Friendly"},
    {'value': "fun", 'text': "Fun"},
    {'value': "funny", 'text': "Funny"},
    {'value': "humorous", 'text': "Humorous"},
    {'value': "informative", 'text': "Informative"},
    {'value': "irreverent", 'text': "Irreverent"},
    {'value': "journalistic", 'text': "Journalistic"},
    {'value': "matteroffact", 'text': "Matter"},
    {'value': "nostalgic", 'text': "Nostalgic"},
    {'value': "objective", 'text': "Objective"},
    {'value': "passionate", 'text': "Passionate"},
    {'value': "poetic", 'text': "Poetic"},
    {'value': "playful", 'text': "Playful"},
    {'value': "professional", 'text': "Professional"},
    {'value': "provocative", 'text': "Provocative"},
    {'value': "quirky", 'text': "Quirky"},
    {'value': "respectful", 'text': "Respectful"},
    {'value': "romantic", 'text': "Romantic"},
    {'value': "sarcastic", 'text': "Sarcastic"},
    {'value': "serious", 'text': "Serious"},
    {'value': "smart", 'text': "Smart"},
    {'value': "snarky", 'text': "Snarky"},
    {'value': "subjective", 'text': "Subjective"},
    {'value': "sympathetic", 'text': "Sympathetic"},
    {'value': "trendy", 'text': "Trendy"},
    {'value': "trustworthy", 'text': "Trustworthy"},
    {'value': "unapologetic", 'text': "Unapologetic"},
    {'value': "upbeat", 'text': "Upbeat"},
    {'value': "witty", 'text': "Witty"},
];

export const writingStyleOptions = [
    {"value": "default", "text": "Default"},
    {"value": "academic", "text": "Academic"},
    {"value": "analytical", "text": "Analytical"},
    {"value": "argumentative", "text": "Argumentative"},
    {"value": "conversational", "text": "Conversational"},
    {"value": "creative", "text": "Creative"},
    {"value": "critical", "text": "Critical"},
    {"value": "descriptive", "text": "Descriptive"},
    {"value": "epigrammatic", "text": "Epigrammatic"},
    {"value": "epistolary", "text": "Epistolary"},
    {"value": "expository", "text": "Expository"},
    {"value": "informative", "text": "Informative"},
    {"value": "instructive", "text": "Instructive"},
    {"value": "journalistic", "text": "Journalistic"},
    {"value": "metaphorical", "text": "Metaphorical"},
    {"value": "narrative", "text": "Narrative"},
    {"value": "persuasive", "text": "Persuasive"},
    {"value": "poetic", "text": "Poetic"},
    {"value": "satirical", "text": "Satirical"},
    {"value": "technical", "text": "Technical"},
];
